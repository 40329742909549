import { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
// slices
import { deleteModal } from 'slices/modal';
// hooks
import { useDispatch } from 'hooks/common/useStore';
import useModal from 'hooks/common/useModal';
// styles
import { mediaValue } from 'cds/styles';

/**
 * 탐색 모달 Hook
 *
 * @example
 * const { onOpenSearch } = useSearchModal();
 *
 * return (
 *   <button onClick={onOpenSearch}>검색 모달 열기</button>
 * );
 */
const useSearchModal = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { initModal } = useModal();

  /** 탐색 모달 열기 */
  const onOpenSearch = () => {
    router.push(`${router.asPath}#search`);
    initModal({ name: 'Search' });
  };

  const checkSearch = useCallback(() => {
    const isExist = /#search/gi.test(router.asPath);
    if (isExist && window.innerWidth < mediaValue.large) {
      initModal({ name: 'Search' });
    } else if (isExist) {
      dispatch(deleteModal('Search'));
    }
  }, [dispatch, router.asPath, initModal]);

  useEffect(() => checkSearch(), [checkSearch]);

  return {
    onOpenSearch,
  };
};

export default useSearchModal;
