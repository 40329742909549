// comonents
import MainPcHeader from 'components/organisms/headers/main/PC';
import MainMobileHeader from 'components/organisms/headers/main/Mobile';
import HeadLayout from 'components/layout/Head';
import BodyLayout from 'components/layout/Body';

/**
 * 메인 레이아웃
 * @param {object} props
 * @param {JSX.Element} props.MobileHeader
 * @param {boolean} props.disableFooter Footer 사용 여부
 */
const Layout = ({ mobileHeader = <MainMobileHeader />, disableFooter, children }) => {
  return (
    <>
      <HeadLayout mobile={mobileHeader} pc={<MainPcHeader />} />
      <BodyLayout disableFooter={disableFooter}>{children}</BodyLayout>
    </>
  );
};

export default Layout;
